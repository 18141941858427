import React, {useState, useEffect, useCallback} from "react";
import styled from "styled-components";
import { useLocalization } from "../../utils/LocalizationContext";

function Header() {
    const [isFixed, setIsFixed] = useState(false)
    // const [isShowMobileMenu, setIsShowMobileMenu] = useState(false)

    const { t } = useLocalization()

    const checkScrollTop = useCallback(() => {
      const offsetFromTop = document.body.scrollTop
      if (!isFixed && offsetFromTop > 75) {
        setIsFixed(true)
      } else if (isFixed && offsetFromTop <= 75) {
        setIsFixed(false)
      }

    }, [isFixed])
  
    useEffect(() => {
        document.body.addEventListener("scroll", checkScrollTop)
      return () => {
        document.body.removeEventListener("scroll", checkScrollTop)
      };
    }, [checkScrollTop])

    return (
        <HeaderStyled $isFixed={isFixed}>
            <a href="/">
                <img 
                    src="/img/svg/logo.svg"
                    width="202"
                    height=""
                    alt="logo"
                />
            </a>
            <LinksContainer>
                <SimpleLink href="/team/" target="_blank" rel="noopener noreferrer">{t("Team")}</SimpleLink>
                {/* 
                <SimpleLink href="/product/" target="_blank" rel="noopener noreferrer">Product</SimpleLink>
                <SimpleLink href="/contact/" target="_blank" rel="noopener noreferrer">Contact</SimpleLink>
                <SimpleLink href="/blog/" target="_blank" rel="noopener noreferrer">Blog</SimpleLink>
                <SimpleLink href="/presse/" target="_blank" rel="noopener noreferrer">Presse</SimpleLink> 
                */}
                <ButtonLinksContainer>
                    <ButtonLink
                        href="mailto:contact@endoless.app"
                    >
                        {t("Get in touch")}
                    </ButtonLink>
                </ButtonLinksContainer>
            </LinksContainer>
            {/*<ButtonMobile
                type="button"
                onClick={() => setIsShowMobileMenu(true)}
            >
                {!isShowMobileMenu && 
                    <img 
                        src="/img/svg/mobileBurger.svg"
                        width=""
                        height=""
                        alt=""
                    />
                }
                {isShowMobileMenu &&
                    <img 
                        src="/img/svg/cross.svg"
                        width=""
                        height=""
                        alt=""
                    />
                }
            </ButtonMobile>*/}
            {/*isShowMobileMenu && 
                <LinksContainerMobile>
                    <SimpleLink href="/product/" target="_blank" rel="noopener noreferrer">Product</SimpleLink>
                    <SimpleLink href="/team/" target="_blank" rel="noopener noreferrer">Team</SimpleLink>
                    <SimpleLink href="/contact/" target="_blank" rel="noopener noreferrer">Contact</SimpleLink>
                    <SimpleLink href="/blog/" target="_blank" rel="noopener noreferrer">Blog</SimpleLink>
                    <SimpleLink href="/presse/" target="_blank" rel="noopener noreferrer">Presse</SimpleLink> 
                    <ButtonLinksContainer>
                        <ButtonMobile
                            type="button"
                            onClick={() => setIsShowMobileMenu(false)}
                        >
                            <img 
                                src="/img/svg/cross.svg"
                                width=""
                                height=""
                                alt=""
                            />
                        </ButtonMobile>
                        <ButtonLink
                            href="mailto:contact@endoless.app"
                        >
                            Get in touch
                        </ButtonLink>
                    </ButtonLinksContainer>
            </LinksContainerMobile>
            */}
        </HeaderStyled>
    )
}

const HeaderStyled = styled("header")`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.875rem 2.5rem;
    background: ${props => props?.$isFixed ? "#fff" : "transparent"};
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;

    @media (max-width: 430px) {
        padding: .975rem 1.5rem;

        & a img {
            width: 70%;
        }
    }
`
const SimpleLink = styled("a")`
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    margin: 0 1.1rem;
`
const LinksContainer = styled("div")`
    display: flex;
    flex-direction: row;
    align-items: center;

    /*@media (max-width: 375px) {
        display: none;
    }*/
`
// const LinksContainerMobile = styled("div")`
//     display: none;
// 
//     @media (max-width: 375px) {
//         display: flex;
//         position: absolute;
//         top: 0;
//         left: 0;
//         flex-direction: column-reverse;
//         flex-wrap: wrap;
//         background: #fff;
//         width: -webkit-fill-available;
//         padding: 1.5rem;
// 
//         & > a {
//             margin: .75rem;
//         }
//     }
// `
const ButtonLinksContainer = styled("div")`
    margin-left: 1.1rem;

    @media (max-width: 430px) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        margin: 0.25rem;
    }
`
const ButtonLink = styled("a")`
    font-weight: 700;
    padding: 0.75rem 2rem;
    margin: 0 .25rem;
    gap: 0.625rem;
    border-radius: 2.625rem;
    color: ${props => props?.$isColored ? "#685ADD" : ""};
    border: 1px solid ${props => props?.$isColored ? "#685ADD" : "#000"};
    width: max-content;

    &:after {
        display: inline-block;
        vertical-align: middle;
        content: ${props => props?.$isColored ? `url('data:image/svg+xml,<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.30664 17.2363L15.1234 8.41953M15.1234 8.41953V15.779M15.1234 8.41953H7.76392" stroke="%23685ADD" stroke-width="1.4075" stroke-linecap="round" stroke-linejoin="round"/></svg>')` : ""};
    }
`
// const ButtonMobile = styled("button")`
//     display: none;
//         
//     @media (max-width: 375px) {
//         display: contents;
//         
//         & img {
//             margin-bottom: 1rem;
//         }
//     }
//     
// `

export default Header;